import { CognitoUser } from '../../common-components/navbar-component';

export async function getUserInfo(): Promise<CognitoUser> {
  try {
    let sharedStateJSON = localStorage.getItem("sharedUserState");
    if (sharedStateJSON) {
      const sharedState = JSON.parse(sharedStateJSON);
      return (sharedState);
    } else {
      let attempts = 0
      while (!sharedStateJSON && attempts < 20) {
        sharedStateJSON = localStorage.getItem("sharedUserState");
        if (!sharedStateJSON) {
          attempts++;
          await new Promise(resolve => setTimeout(resolve, 300));
        }
      }
      const sharedState = JSON.parse(sharedStateJSON);
      return (sharedState);
    }
  } catch {
    console.error('error');
    return {
      signInUserSession: {
        idToken: {
          payload: {
            firstSurname: '',
            email: '',
            firstName: ''
          }
        }
      }
    };
  }
}

