// Primero, importa useQuery de react-query
import { useQuery } from 'react-query';
import * as React from 'react';
import { getUserNotificationsDS } from '../components/services/notificationService';
import { InboxIcon } from '@heroicons/react/24/outline';
import caseClosedSound from './media/notification.mp3';
import { NotificationProject, NotificationStatus } from '../components/interfaces/notification_counter';

interface NotificationCounterProps {
  ogmioToken: string | null;
}

const NotificationCounter: React.FC<NotificationCounterProps> = ({ ogmioToken }) => {
  // Define una función para fetchear las notificaciones
  const fetchNotifications = async () => {
    const sevenDaysAgoEpoch = Math.floor(Date.now() / 1000) - 7 * 24 * 60 * 60;
    const input = {
      to: 'LAYER_ADL_HACKERS', // Asegúrate de tener este valor definido correctamente
      currentstatus: NotificationStatus.pending, // Ajusta según sea necesario
      date: sevenDaysAgoEpoch.toString()
    };
    return await getUserNotificationsDS(input as NotificationProject, ogmioToken);
  };

  // Utiliza useQuery para manejar la solicitud y el estado de las notificaciones
  const { data, refetch } = useQuery('notifications', fetchNotifications, {
    enabled: !!ogmioToken, // Solo ejecuta la query si ogmioToken no es null
    onSuccess: data => {
      // Sonido de notificacion
      // if (data.totalItems > 0) {
      //   playNotificationSound();
      // }
    },
    // Configura el intervalo de refetching según sea necesario
    refetchInterval: 600000,
    refetchIntervalInBackground: true
  });

  const notificationCount = data?.totalItems ?? 0;

  const playNotificationSound = () => {
    const audio = new Audio(caseClosedSound);
    audio.play().catch(error => console.error('Error al reproducir el sonido de notificación:', error));
  };

  return (
    <div className="flex items-center">
      <div className="relative">
        <InboxIcon className="h-8 w-8 text-white cursor-pointer" onClick={() => refetch()} />
        {notificationCount > 0 && (
          <div className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-4 h-4 flex justify-center items-center text-xs">
            {notificationCount}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationCounter;
