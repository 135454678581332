import { getUserInfo } from '../components/services/get-user-info';
import { Fragment, useState, useEffect } from 'react'
import { Disclosure, Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UserIcon,
  XMarkIcon,
  UsersIcon,
  UserCircleIcon,
  InboxArrowDownIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import NotificationCounter from './notificationCounter';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar(props: NavbarProps) {
  
  const initialUserValue = ({
    signInUserSession: {
      idToken: {
        payload: {
          firstSurname: '',
          email: '',
          firstName: ''
        }
      }
    }
  });

  const ogmioToken = props.ogmioToken
  const [currentUser, setCurrentUser] = useState(initialUserValue);
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
    
  function logout () {
    try {
      props.onLogout();
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {    
    async function UserInfo() {
      if (initialUserValue == currentUser) {
        await getUserInfo().then((user: CognitoUser) => {
          setCurrentUser(user);
          setLoading(false);
        })
      }
    }

    UserInfo();
  }, [currentUser, initialUserValue]);

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleNavbarClick = () => {
    setShowDialog(true);
  };

  const navigation = [
    { name: 'Home', href: 'home', icon: HomeIcon, current: pathValidation("/home") },
    { name: 'Profile', href: 'profile', icon: UserIcon, current: pathValidation("/profile") },
    { name: 'Members', href: 'member-creation', icon: UsersIcon, current: pathValidation("/member-creation") },
    { name: 'Accounts', href: 'accounts', icon: FolderIcon, current: pathValidation("/accounts") },
    { name: 'Layers', href: 'layers', icon: CalendarIcon, current: pathValidation("/layers") },
    { name: 'Inbox', href: 'user-request', icon: InboxArrowDownIcon, current: pathValidation("/user-request") },
  ]
  const userNavigation = [
    { name: 'Your profile', href: '' },
    { name: 'Sign out', href: '#' },
  ]
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function pathValidation(path) {
    if (window.location.pathname == path) {
      return true
    } else {
      return false
    }

  }

  function LoginValidation(props) {
      return (
        <div>
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-vulcanus_delft_blue-400 bg-vulcanus_delft_blue px-4 shadow-sm sm:gap-x-4 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-vulcanus_cool_gray-900"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" />
            </button>
            {/* Separator */}
            <div className="h-6 w-px bg-vulcanus_delft_blue/10" aria-hidden="true" />
            <div className="flex flex-1 gap-x-2 self-stretch">
              <div className="items-center relative flex flex-1">
                <img
                  className="h-8 w-auto lg:block"
                  src={require(`./images/Logos_Horizontal/logo-transparent-png.png`)}
                  alt="Your Company"
                />
              </div>

              {/* Agregar NotificationCounter aquí */}
              {ogmioToken !== null && (
                <>
                  <NotificationCounter ogmioToken={ogmioToken} />
                </>
              )}

              <div className="flex items-center gap-x-2">
                {/* Separator */}
                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-vulcanus_delft_blue/10" aria-hidden="true" />
                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img className="h-8 w-8 rounded-full bg-gray-50" src={require(`./images/Profile.png`)} alt="" />
                    {/* <UserCircleIcon className="h-8 w-8 text-white" /> */}
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-vulcanus_cool_gray-900"
                        aria-hidden="true"
                      >
                        {currentUser?.signInUserSession?.idToken.payload.firstName}{' '}
                        {currentUser?.signInUserSession?.idToken.payload.firstSurname}
                      </span>
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-vulcanus_ghost_white-900 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map(item => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                              )}
                              onClick={logout}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      );
  }

  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-vulcanus_delft_blue/80" />
            </Transition.Child>
            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-vulcanus_cool_gray-900" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-vulcanus_delft_blue px-6 pb-4 ring-1 ring-vulcanus_delft_blue-400/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src={require(`./images/Logos_Horizontal/logo-transparent-png.png`)}
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? 'bg-vulcanus_paynes_gray text-vulcanus_cool_gray-900'
                                      : 'text-vulcanus_cool_gray-900 hover:text-vulcanus_cool_gray-900 hover:bg-vulcanus_paynes_gray',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Navbar artifact*/}
        <LoginValidation/>
      </div>
    </>
  );
}

export interface NavbarProps {
  onLogout: () => void;
  ogmioToken: string | null;
}

export interface CognitoUser {
  username?: string;
  pool?: {
    userPoolId: string;
    clientId: string;
    client: {
      endpoint: string;
      fetchOptions: object;
    };
    advancedSecurityDataCollectionFlag: boolean;
    storage: {
      [key: string]: string;
    };
  };
  Session?: null;
  client?: {
    endpoint: string;
    fetchOptions: object;
  };
  signInUserSession: {
    idToken: {
      jwtToken?: string;
      payload: {
        at_hash?: string;
        sub?: string;
        plugins?: string;
        roles?: string;
        iss?: string;
        phones?: string;
        userMenu?: string;
        identities?: Array<{
          userId: string;
          providerName: string;
          providerType: string;
          issuer: string;
          primary: string;
          dateCreated: string;
        }>;
        auth_time?: number;
        id?: string;
        exp?: number;
        iat?: number;
        firstSurname: string; /// here
        email: string; /// here
        secondName?: string;
        address?: string;
        email_verified?: boolean;
        'cognito:username'?: string;
        githubUser?: string;
        nonce?: string;
        firstName: string; /// here
        aud?: string;
        token_use?: string;
        secondSurName?: string;
        name?: string;
        githubEmuUser?: string;
        imageProfile?: string;
      };
    };
    refreshToken?: {
      token: string;
    };
    accessToken?: {
      jwtToken: string;
      payload: {
        sub: string;
        token_use: string;
        scope: string;
        auth_time: number;
        iss: string;
        exp: number;
        iat: number;
        version: number;
        jti: string;
        client_id: string;
        username: string;
      };
    };
    clockDrift?: number;
  };
  authenticationFlowType?: string;
  storage?: {
    [key: string]: string;
  };
  keyPrefix?: string;
  userDataKey?: string;
  attributes?: {
    sub: string;
    identities: string;
    email_verified: boolean;
    name: string;
    email: string;
  };
  preferredMFA?: string;
}
