import * as React from 'react';
import { useState, useEffect } from 'react';
import '../../tailwind.scss';
import Navbar, { NavbarProps } from '../../common-components/navbar-component';

export default function BaseComponent(): JSX.Element {
  const [ogmioToken, setOgmioToken] = useState<string | null>(null);

  useEffect(() => {
    const handler = (e: any) => {
      if (!ogmioToken) {
        setOgmioToken(e.detail.tokenValue);
      }
    };

    window.addEventListener('userToken', handler);
    
    return () => {
      window.removeEventListener('userToken', handler);
    };
  }, [ogmioToken]);

  const navbarProps: NavbarProps = {
    onLogout: async () => {
      window.dispatchEvent(new CustomEvent('requestLogout'));
    },
    ogmioToken: ogmioToken
  };

  return (
    <>
      <Navbar {...navbarProps} />
    </>
  );
}
