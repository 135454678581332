export interface UserNotification {
  id: string;
  from: string;
  to?: string;
  currentdate?: string;
  message?: string;
  currentstatus: string;
  request: RequestUserNotification;
  audit: AuditUserNotification[];
}

export interface RequestUserNotification {
  id: string;
  email?: string;
  layerId: string;
  roleId: string;
  tools?: Variable[];
}

export interface AuditUserNotification {
  statusPrev: string;
  statusNew: string;
  date: string;
  from: string;
}

export interface Variable {
  // Define la interfaz según tu estructura
}

export interface PageInput {
  limit: number;
  page: number;
  filter: Filter[];
}

export interface Filter {
  key: string;
  value: string;
}

export interface NotificationRequestInput {
  id: string;
  newStatus: NotificationStatus;
  message: string;
  userID: string;
}

export enum NotificationStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  failed = 'failed'
}

export interface NotificationProject {
  to?: string;
  from?: string;
  date: string;
  currentstatus?: NotificationStatus;
  email?: string;
  role?: string;
  limit?: number | undefined;
  page?: number | undefined;
}

export interface PaginatedUserNotification {
  data: UserNotification[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
}

export interface VariableInput {
  name: string;
  value: string;
}

export interface Notification {
  _id: {
    $oid: string;
  };
  from: string;
  to: string;
  message: string;
  currentstatus: string;
  request: {
    id: string;
    email: string;
    layerid: string;
    roleid: string;
    tools: {
      name: string;
      value: string;
    }[];
  };
  audit: {
    statusprev: string;
    statusnew: string;
    date: string;
    from: string;
  }[];
  currentdate: string;
}
