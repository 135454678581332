import axios from 'axios';
import { PaginatedUserNotification, NotificationProject } from '../interfaces/notification_counter';
import { getUserNotificationsByDateAndStatus } from '../graphql/queries';

const URL = process.env.GRAPHQLURL !== undefined ? process.env.GRAPHQLURL : '';

export const getUserNotificationsDS = async (
  input: NotificationProject,
  ogmioToken: string
): Promise<PaginatedUserNotification> => {
  try {
    const config = {
      headers: { Authorization: ogmioToken, 'Content-Type': 'application/graphql' }
    };

    const graphql = JSON.stringify({
      query: getUserNotificationsByDateAndStatus,
      variables: {
        input: input
      }
    });

    const { data } = await axios.post(URL, graphql, config);

    return data.data.getUserNotificationsByDateAndStatus;
  } catch (error) {
    throw new Error(`Error fetching paginated user notifications: ${error.message}`);
  }
};
