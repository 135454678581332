import * as React from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import BaseComponent from './components/navbar/base-component';
import './tailwind.scss';

export default function Root() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <BaseComponent />
    </QueryClientProvider>
  );
}
