export const getAllHefestoAccountsConstants = `query getAllHefestoAccountsConstants{
    getAllHefestoAccountsConstants{
      id
      value {
        id
        label
        env {
          id
          type
        }
        stack {
          id
          label
        }
        title
      }
    }
  }`;

export const getUserNotificationsByDateAndStatus = /* GraphQL */ `
  query getUserNotificationsByDateAndStatus($input: NotificationProject) {
    getUserNotificationsByDateAndStatus(input: $input) {
      data {
        id
        from
        to
        currentdate
        message
        currentstatus
        request {
          id
          email
          layerId
          roleId
          tools {
            name
            value
          }
        }
      }
      pageIndex
      pageSize
      totalPages
      totalItems
    }
  }
`;
